import React, { useEffect, useState } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import useReport from "../../data-access/useReport";

const Report = ({ type }) => {
  // const { report, reportLoading } = useReport({ type });
  let report = [];
  if (type === "audit")
    report = [
      {
        url: ["/pdfs/Excellent Trust Audit Report 21-22.pdf"],
        name: "Excellent Trust Audit Report 21-22",
      },
      {
        url: ["/pdfs/Excellent Audited Report 2022-23.pdf"],
        name: "Excellent Audited Report 2022-23",
      },
      {
        url: ["/pdfs/Excellent Civil Academy Trust 2023-24.pdf"],
        name: "Excellent Civil Academy Trust 2023-24",
      },
    ];
  else if (type === "annual")
    report = [
      {
        url: ["/pdfs/Annual Report 2023-24_compressed.pdf"],
        name: "Annual Report 2023-24_compressed",
      },
      {
        url: ["/pdfs/Annual Report 2022-23.pdf"],
        name: "Annual Report 2022-23",
      },
    ];
  else if (type === "registration")
    report = [
      {
        url: ["/pdfs/GST Registration.pdf"],
        name: "GST Registration",
      },
      {
        url: ["/pdfs/12AA Excellent Civil Academy Trust.pdf"],
        name: "12AA Excellent Civil Academy Trust",
      },
      {
        url: ["/pdfs/80G Excellent Civil Academy Trust.pdf"],
        name: "80G Excellent Civil Academy Trust",
      },
      {
        url: ["/pdfs/Approval Letter for form CSR1.PDF"],
        name: "Approval Letter for form CSR1",
      },
      {
        url: ["/pdfs/ECAT Deed_11zon.pdf"],
        name: "ECAT Deed_11zon",
      },
      {
        url: ["/pdfs/Excellent EPF.pdf"],
        name: "Excellent EPF",
      },
      {
        url: ["/pdfs/Excellent ESI.pdf"],
        name: "Excellent ESI.pdf",
      },
      {
        url: ["/pdfs/ECAT Registration-1.pdf"],
        name: "ECAT Registration-1",
      },
      {
        url: ["/pdfs/Excellent PAN Card.pdf"],
        name: "Excellent PAN Card",
      },
      {
        url: ["/pdfs/MSME Registration Udhyam.pdf"],
        name: "MSME Registration Udhyam",
      },
      {
        url: ["/pdfs/NGO Darpan.pdf"],
        name: "ENGO Darpan",
      },
      {
        url: ["/pdfs/Registration-Certificate FCRA ECAT.pdf"],
        name: "Registration-Certificate FCRA ECAT",
      },
    ];
  const [view, setView] = useState(
    report?.[0]?.url?.[0] ??
      "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
  );

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform = (slot) => ({
    ...slot,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    Print: () => <></>,
    PrintMenuItem: () => <></>,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
  });

  // useEffect(() => {
  //   if (report)
  //     setView(
  //       report?.[0]?.url?.[0] ??
  //         "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
  //     );
  // }, [report]);

  return (
    <div className="flex p-4">
      <div className="flex h-[85vh] w-[25vw] p-4 justify-center">
        <div className="flex flex-col w-[100%] gap-4 overflow-y-scroll">
          {report?.map((rep) => (
            <div
              onClick={() => setView(rep?.url?.[0])}
              className="flex flex-col gap-4 cursor-pointer hover:bg-gray-200 w-[70%] h-[150px] self-center border border-4 border-gray-300 rounded-lg p-2"
            >
              <div className="flex h-[60%]">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={rep?.url?.[0]}
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                    }}
                    defaultScale={SpecialZoomLevel.PageFit}
                  />
                </Worker>
              </div>

              <div className="flex h-[20%] justify-center text-center font-semibold">
                {rep?.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex h-[85vh] w-[75vw] overflow-y-scroll">
        <div
          className="rpv-core__viewer"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#eeeeee",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              display: "flex",
              padding: "0.25rem",
            }}
          >
            <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          </div>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
            }}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={view}
                plugins={[toolbarPluginInstance]}
                defaultScale={1.25}
              />
            </Worker>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
